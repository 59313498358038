import { HTMLProps } from 'react'

const Label = ({
  className,
  children,
  ...props
}: HTMLProps<HTMLLabelElement>) => (
  <label
    className={`${className} block font-medium text-sm text-neutral-700`}
    {...props}
  >
    {children}
  </label>
)

export default Label
