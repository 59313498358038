import styles from './styles.module.css'
// import AppleIcon from '@mui/icons-material/Apple'
// import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import Link from 'next/link'
import React, { ReactNode } from 'react'

interface AuthCardI {
  children: ReactNode;
  text: string;
  isProviders: boolean;
}

const AuthCard = ({ children, text, isProviders }: AuthCardI) => (
  <div
    className={`min-h-screen flex flex-col justify-center items-center pt-6 sm:pt-0 ${styles.cover}`}
  >
    <div
      style={{ paddingTop: isProviders ? '10rem' : '5.5rem' }}
      className={`w-[90%] sm:max-w-md mt-6 px-6 pb-10 relative bg-white rounded-xl ${styles.shadow}`}
    >
      <div
        style={{ height: isProviders ? '10rem' : '5.5rem' }}
        className='w-[90%] flex flex-col justify-between p-7 text-center top-[-40px] m-auto left-0 right-0 rounded-xl shadow-lg  bg-gradient-to-tr from-[#1a1a1a]  to-[#3e3e44] absolute '
      >
        <p className='text-white text-2xl font-extrabold'>{text}</p>
        <div
          style={{ display: isProviders ? 'flex' : 'none' }}
          className=' gap-8 justify-center items-center '
        >
          {/* <Link href={`${process.env.NEXT_PUBLIC_BACKEND_URL}/login/apple`}>
            <AppleIcon
              sx={{
                fontSize: '42px' // Set the desired size here
              }}
              className='text-white relative top-[-2px] '
            />
          </Link> */}
          <Link href={`${process.env.NEXT_PUBLIC_BACKEND_URL}/login/google`}>
            <GoogleIcon
              sx={{
                fontSize: '35px' // Set the desired size here
              }}
              className='text-white '
            />
          </Link>
          {/* <Link href={`${process.env.NEXT_PUBLIC_BACKEND_URL}/login/facebook`}>
            <FacebookIcon
              sx={{
                fontSize: '35px' // Set the desired size here
              }}
              className='text-white '
            />
          </Link> */}
        </div>
      </div>
      {children}
    </div>
  </div>
)

export default AuthCard
