import GuestLayout from '@components/layout/GuestLayout'
import AuthCard from '@components/pages/authentication/login/AuthCard'
import Input from '@components/shared/Input'
import InputError from '@components/shared/InputError'
import Label from '@components/shared/Label'
import { useAuth } from '@hooks/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const Login = () => {
  const router: any = useRouter()

  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/dashboard/api-keys'
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [shouldRemember] = useState(false)
  const [errors, setErrors] = useState<any>([])
  const [status, setStatus] = useState<string | null>(null); // eslint-disable-line

  useEffect(() => {
    if (router.query.reset?.length > 0 && errors.length === 0) {
      setStatus(atob(router.query.reset) as string) // Cast the result to string
    } else {
      setStatus(null)
    }
  }, [router.query.reset, errors])

  const submitForm = async (event: any) => {
    event.preventDefault()

    login({
      email,
      password,
      remember: shouldRemember,
      setErrors,
      setStatus
    })
  }

  return (
    <GuestLayout>
      <AuthCard isProviders={true} text={'Login'}>
        <form onSubmit={submitForm}>
          {/* Email Address */}
          <div>
            <Label htmlFor='email'>Email</Label>
            <Input
              id='email'
              type='email'
              value={email}
              className='block w-full mt-1'
              onChange={(event: any) => setEmail(event.target.value)}
              required
              autoFocus
            />
            <InputError messages={errors.email} className='mt-2' />
          </div>
          {/* Password */}
          <div className='mt-4'>
            <Label htmlFor='password'>Password</Label>
            <Input
              id='password'
              type='password'
              value={password}
              className='block w-full mt-1'
              onChange={(event: any) => setPassword(event.target.value)}
              required
              autoComplete='current-password'
            />
            <InputError messages={errors.password} className='mt-2' />
          </div>

          <div className='flex items-center justify-end mt-2 mb-10'>
            <Link
              href='/forgot-password'
              className='text-sm !font-extralight text-neutral-500 hover:text-neutral-700'
            >
              Forgot password?
            </Link>
          </div>
          <button
            type='submit'
            className='bg-gradient-to-tr from-[#1a1a1a] mb-10 hover:shadow-xl transition w-full duration-300 to-[#3e3e44] font-extrabold rounded-md cursor-pointer text-center text-white p-2 '
          >
            Login
          </button>

          <div className='flex justify-center w-full'>
            <div className='mr-1 text-sm font-light text-neutral-500'>
              Don&apos;t have an account?
            </div>
            <Link
              href='/register'
              style={{ fontWeight: 800 }}
              className='text-sm text-neutral-800'
            >
              Sign Up
            </Link>
          </div>
        </form>
      </AuthCard>
    </GuestLayout>
  )
}

export default Login
