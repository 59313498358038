import Head from 'next/head'
import React, { ReactNode } from 'react'

const GuestLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <Head>
        <title>Laravel</title>
      </Head>
      <div className=' text-neutral-900 antialiased'>{children}</div>
    </div>
  )
}

export default GuestLayout
